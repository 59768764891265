import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pitched-roofing',
  templateUrl: './pitched-roofing.component.html',
  styleUrls: ['./pitched-roofing.component.scss']
})
export class PitchedRoofingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  public scrollToTop() {
    window.scrollTo(0, 0);
  }

}
