import { Component, OnInit } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomePageComponent implements OnInit {
  public flatRoof = false;
  public pitchedRoof = false;
  public joineryWorks = false;

  constructor() { }

  ngOnInit(): void {
  }

  public scrollToTop() {
    window.scrollTo(0, 0);
  }

  public toggleFlatRoof() {
    if (this.flatRoof === false) {
      this.flatRoof = true;
    } else {
      this.flatRoof = false;
    }
  }

  public togglePitchedRoof() {
    if (this.pitchedRoof === false) {
      this.pitchedRoof = true;
    } else {
      this.pitchedRoof = false;
    }
  }

  public toggleJoineryWorks() {
    if (this.joineryWorks === false) {
      this.joineryWorks = true;
    } else {
      this.joineryWorks = false;
    }
  }

}
