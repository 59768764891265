// import { NgModule } from '@angular/core';
// import { Routes, RouterModule } from '@angular/router';


// const routes: Routes = [];

// @NgModule({
//   imports: [RouterModule.forRoot(routes)],
//   exports: [RouterModule]
// })
// export class AppRoutingModule { }

import { ContactUsComponent } from './contact-us/contact-us.component';
import { JoineryWorksComponent } from './joinery-works/joinery-works.component';
import { PitchedRoofingComponent } from './pitched-roofing/pitched-roofing.component';
import { FlatRoofingComponent } from './flat-roofing/flat-roofing.component';
import { HomePageComponent } from './home-page/home-page.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  { path: 'home', component: HomePageComponent },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'flat-roofing', component: FlatRoofingComponent },
  { path: 'pitched-roofing', component: PitchedRoofingComponent },
  { path: 'joinery-works', component: JoineryWorksComponent },
  { path: 'contact-us', component: ContactUsComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
