import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  // public show = false;
  public toggle;

  constructor() { }

  ngOnInit(): void {
    this.toggle = document.getElementById("servicesLinks");
  }

  public menuToggle() {
    const x = document.getElementById("myLinks");
    if (x.style.display === "block") {
      x.style.display = "none";
    } else {
      x.style.display = "block";
    }
  }

  public menuTablet() {
    const x = document.getElementById("servicesLinks");
    if (this.toggle.style.display === "block") {
      this.toggle.style.display = "none";
    } else {
      this.toggle.style.display = "block";
    }
  }

  // public toggleMenu() {
  //   if (this.show === false) {
  //     this.show = true;
  //   } else {
  //     this.show = false;
  //   }
  // }
}
